<template>
  <div class="modal-mask" @click.prevent="close">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        @click.stop=""
        :style="cssProps"
        :class="{ verticalLayout: verticalLayout }">
        <h4 class="modal-title">
          <slot name="title"></slot>
        </h4>
        <div class="model-content">
          <slot></slot>
        </div>

        <div class="actions column">
          <button @click="clickYes" class="contained primary">
            {{ nameYes }}
          </button>
          <button @click="clickNo" class="text">
            {{ nameNo }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      nameYes: {
        type: String,
      },
      funcYes: {
        type: Function,
      },
      nameNo: {
        type: String,
      },
      funcNo: {
        type: Function,
      },
      maxWidth: {
        type: String,
        default: "450",
      },
      verticalLayout: {
        type: Boolean,
        default: false,
      },
      hideCloseButton: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["yes", "no"],
    methods: {
      close() {
        this.$emit("close");
      },

      clickYes() {
        if (this.funcYes != null) {
          this.funcYes();
        }

        this.$emit("no");
      },

      clickNo() {
        if (this.funcNo != null) {
          this.funcNo();
        }
        this.$emit("no");
      },
    },
    computed: {
      cssProps() {
        return {
          "--max-width": `${this.maxWidth}px`,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: table;
    padding: 2rem;

    &.verticalLayout {
      height: 90vh;
      padding: 0;
    }
  }

  .modal-icon {
    position: absolute;
    top: 8px;
    left: 5px;
    font-size: 1.45rem;
  }

  .modal-title {
    margin: 0 0 10px;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .prompt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      button {
        width: 50%;
        max-width: 160px;
        min-width: 100px;
        align-items: center;
        justify-content: center;
        gap: 5px;
        &.with-icon {
          padding-left: 0;
        }
        &.text {
          background: none;
          font-size: 0.875rem;
          text-align: center;
          color: #555;
          text-decoration: underline;
        }
        &:disabled,
        &[disabled] {
          &.text {
            color: #aaa;
          }
        }
      }
    }
  }

  .modal-container {
    position: relative;
    max-width: var(--max-width);
    max-height: 90vh;
    margin: 0px auto;
    padding: 1rem;
    background-color: #fff;
    border-radius: $card_radius;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
    overflow: auto;

    &.verticalLayout {
      height: 90vh;
    }

    @media screen and (max-width: 375px) {
      padding: 20px;
    }
  }

  .close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 9;
  }

  .model-content {
    margin: 1rem;
    h4 {
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  .actions {
    gap: 5px;
    align-items: center;
  }
</style>
